<template>
	<div class="page flex-col">
		<div class="box_1 flex-col" :class="startX<=600?'wid600':''">
			<div class="box_2 flex-col">
				<div class="" v-if="startX<=600">
					<head-top :type="3" :startX="startX"></head-top>
					<!-- <img class="image1"
						src="./assets/img/pso93cck62rjtini4woobq79c3n12ves9k3d1e9616-f534-40d5-9c0c-c27f694a6e93.png"
						alt=""> -->
						<div class="image1" @click.stop="toIndex(dataMain.banner.picture[0])">
							<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
								style="width: 100%;height:100%">
							<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
						</div>
					<!-- <el-carousel height="100%" class="image1">
						<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id"  @click.stop="toIndex(item)">
							<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
								style="width: 100%;height:100%">
							<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
						</el-carousel-item>
					</el-carousel> -->
				</div>
				<div class="section_1 flex-col" v-else>
					<head-top :type="3" :startX="startX"></head-top>
					<div class="section1" @click.stop="toIndex(dataMain.banner.picture[0])">
						<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
					</div>
					<!-- <el-carousel height="100%" class="section1">
						<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id" @click.stop="toIndex(item)">
							<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
								style="width: 100%;height:100%">
							<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
						</el-carousel-item>
					</el-carousel> -->
				</div>
				<div class="section_4 flex-row justify-between" id="news">
					<div class="box_4 flex-col">
						<div class="text-wrapper_1">
							<span class="text_10">N</span>
							<span class="text_11">ews</span>
						</div>
						<span class="text_12">新闻动态</span>
						<div class="flex-col title1">
							<div class="box" @click="toType(item.category_id)" v-for="item in dataMain.news"
								:key="item.category_id">
								<span class="text_13" :class="category_id==item.category_id?'act':''">{{item.name}}</span>
								<div class="box_5" v-if="category_id==item.category_id"></div>
							</div>
						</div>
					</div>
					<div class="box_6 flex-col">
						<div class="group_1 flex-row" @click="toDetail(item.news_id)" v-for="(item,index) in newList"
							:key="index">
							<span class="text_16">{{item.d}}</span>
							<span class="text_17">/</span>
							<div class="text-wrapper_2 flex-col justify-between">
								<span class="text_18">{{item.month}}</span>
								<span class="text_19">{{item.year}}</span>
							</div>
							<div class="image_3"></div>
							<div class="text-wrapper_3 flex-col">
								<span class="text_20">{{item.title}}</span>
								<span class="text_21">{{item.prop}}</span>
								<span class="text_22">查看更多</span>
							</div>
						</div>
					</div>
				</div>
				<div class="section_5 flex-row justify-end">
					<div class="text-wrapper_18 flex-align-justify" @click="toPage(1)">
						<span class="text_72">第一页</span>
					</div>
					<div class="image-wrapper_2 flex-align-justify" v-if="p!=1" @click="toPage(p-1)">
						<img class="thumbnail_2" referrerpolicy="no-referrer"
							src="./assets/img/psepnt32v816jetbtwnjfs1np5niiwkmk8215e3f7e-ad71-407b-8226-ed3fa51ebbe3.png" />
					</div>
					<div class="text-wrapper_19 flex-align-justify" :class="p==item?'bgBlue':''"
						v-for="(item,index) in pageList" :key="index" @click="toPage(item)">
						<span class="text_73">{{item}}</span>
					</div>
					<div class="image-wrapper_3 flex-align-justify" v-if="p!=currentPages" @click="toPage(p*1+1)">
						<img class="thumbnail_3" referrerpolicy="no-referrer"
							src="./assets/img/psacivvy01sar12fq4zef49dx8si603mp2c7f6eba9a-e922-4403-b3f6-b99642d1fb77.png" />
					</div>
					<div class="text-wrapper_23 flex-align-justify" @click="toPage(currentPages)">
						<span class="text_77">最后一页</span>
					</div>
				</div>
			</div>
		</div>
		<div class="" v-if="startX>600">
			<body-footer></body-footer>
		</div>
		<div class="" v-else>
			<body-footer1></body-footer1>
		</div>
	</div>
</template>
<script>
	import headTop from "../../components/headTop.vue"
	import bodyFooter from "../../components/bodyFooter.vue";
	import bodyFooter1 from "../../components/bodyFooter1.vue";
	import {
		newsIndex,
		newsList
	} from "../../util/api";
	export default {
		data() {
			return {
				constants: {},

				// category_id: 1,
				startX: 1200,
				dataMain: {},
				newList: [],
				show: 3,
				p: 1,
				currentPages: 5,
				pageList: []
			};
		},
		components: {
			headTop,
			bodyFooter,
			bodyFooter1
		},
		created() {
			this.getDate()
		},
		mounted() {
			// this.category_id = this.$route.params.id;
			this.startX = document.body.clientWidth;
			window.onresize = () => {
				return (() => {
					this.startX = document.body.clientWidth;
				})();
			};
			window.addEventListener("scroll", this.scrollHandle1, true);
		},
		deforeDestroy(){
			window.removeEventListener('scroll', this.scrollHandle, true);
		},
		watch: {
			startX(val, oldval) {
				if (val != oldval) {
					// console.log(this.startX, "屏幕宽度");
				}
			},
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
			category_id(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},	
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
			category_id() {
				// console.log(this.$route.params.id, "id");
				return this.$route.params.id
			}
		},
		methods: {
			getDate() {
				newsIndex({
					lang: this.lang
				}).then((res) => {
					console.log(res, this.category_id);
					this.dataMain = res.data
					if (this.category_id == '' || this.category_id == undefined) {
						this.category_id = this.dataMain.news[0].category_id
					}
					this.getlist()
					// this.title = res.data.header_nav;
				});
			},
			getlist() {
				newsList({
					lang: this.lang,
					category_id: this.category_id,
					p: this.p
				}).then((res) => {
					console.log(res);
					this.newList = res.data.list
					this.p = res.data.p
					this.currentPages = res.data.pages * 1
					console.log(this.currentPages);
					let list = []
					if (this.p == 1) {
						for (let i = 1; i <= this.currentPages; i++) {
							if (i < 6) {
								list.push(i)
							}
						}
					} else {
						list.push(this.p - 1)
						for (let i = this.p; i <= this.currentPages; i++) {
							if (i < (this.p + 4)) {
								list.push(i)
							}
						}
					}
					this.pageList = list
				});
			},
			toPage(num) {
				this.p = num
				this.getlist()
			},
			toType(id) {
				// this.category_id = id
				// this.getlist()
				this.$router.push("/news/"+id);
			},
			toDetail(news_id) {
				this.$router.push("/newDetail/" + news_id);
			},
			scrollHandle1() {
				let news = this.isInViewPortOfOne(document.getElementById('news'))
				if (news) {
					document.getElementById('news').style.transform = 'scale(1)'
					document.getElementById('news').style.opacity = '1'
				}else{
					document.getElementById('news').style.transform = 'scale(0.8)'
					document.getElementById('news').style.opacity = '0'
				}
			},
			isInViewPortOfOne(el) {
				// viewPortHeight 兼容所有浏览器写法
				const offset = el.getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					return true
				} else {
					return false
					// console.log('移出可视区域');
				}
			},
			toIndex(v) {
				if (v.target_rule==1){
					this.$router.push("/");
				}else if (v.target_rule==2){
					this.$router.push("/aboutUs");
				}else if (v.target_rule==3){
					let id = v.param
					this.$router.push("/news/"+id);
				}else if (v.target_rule==4){
					let news_id = v.param
					this.$router.push("/newDetail/"+news_id);
				}else if (v.target_rule==5){
					let id = v.param
					console.log(id);
					this.$router.push("/product/"+id);
				}else if (v.target_rule==6){
					this.$router.push("/companies");
				}else if (v.target_rule==7){
					this.$router.push("/Investor");
				}else if (v.target_rule==8){
					this.$router.push("/invite");
				}else if (v.target_rule==9){
					this.$router.push("/contact");
				}
			},
		}
	};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style lang="scss" scoped>
	.wid600 {
		width: 100%;

		.box_2 {
			width: 100%;
			height: auto;
			background-color: rgba(255, 255, 255, 1);

			.section_4 {
				height: auto;
				flex-direction: column;
				margin: 13.333rem auto 0;

				.box_4 {
					height: auto;
					padding-left: 7rem;

					.text-wrapper_1 {
						text-transform: uppercase;
						font-size: 8.667rem;
						line-height: 7.333rem;
					}

					.text_12 {
						height: auto;
						margin-top: 4rem;
						font-size: 5rem;
						line-height: 7.333rem;
					}

					.title1 {
						flex-direction: row;
						margin-bottom: 4rem;

						.box {
							margin-top: 8rem;
							margin-right: 13.667rem;

							.text_13 {
								font-size: 4.333rem;
								line-height: 4.333rem;
							}

							.box_5 {
								width: 17.333rem;
								height: 1rem;
								background: #0390CF;
								border-radius: 0.5rem;
								margin: 2.5rem 0 0 0.3rem;
							}

							&:nth-last-child(1) {
								margin-right: 0;
							}
						}
					}
				}

				.box_6 {
					.group_1 {
						width: auto;
						height: auto;
						margin: 5.333rem 0 0 6rem;

						.text_16 {
							width: auto;
							height: auto;
							margin-top: 6.667rem;
							font-size: 8rem;
							line-height: 8rem;
						}

						.text_17 {
							width: auto;
							height: auto;
							margin: 0;
							margin-top: 7.5rem;
							margin-left: 1rem;
							font-size: 6rem;
							line-height: 6rem;
						}

						.text-wrapper_2 {
							width: auto;
							height: 6.333rem;
							margin-top: 7.5rem;

							.text_18 {
								width: auto;
								height: auto;
								font-size: 3rem;
								line-height: 3rem;
							}

							.text_19 {
								width: auto;
								height: auto;
								margin-top: 0;
								font-size: 3rem;
								line-height: 3rem;
							}
						}

						.image_3 {
							width: 0.333rem;
							height: 18.333rem;
							border-radius: 0.2rem;
							margin-left: 6.667rem;
						}

						.text-wrapper_3 {
							width: auto;
							height: auto;
							margin-left: 6.667rem;

							.text_20 {
								width: 74rem;
								height: auto;
								font-size: 4.333rem;
								line-height: 6rem;
								overflow: hidden;
							}

							.text_21 {
								width: 74rem;
								height: auto;
								font-size: 3.667rem;
								line-height: 6rem;
								margin-top: 4rem;
								text-overflow: ellipsis;
								overflow: hidden;
							}

							.text_22 {
								font-size: 3.667rem;
								line-height: 6rem;
								margin-top: 5.333rem;
							}
						}
					}

					.image_4 {
						width: 111rem;
						height: 0.7rem;
						margin: 0 auto;
						margin-top: 5rem;

					}
				}
			}

			.section_5 {
				width: 100%;
				height: auto;
				margin: 11rem auto;
				justify-content: center;

				.text-wrapper_18 {
					width: 12.333rem;
					height: 6.667rem;
					border-radius: 0.667rem;

					.text_72 {
						font-size: 2.667rem;
						line-height: 2.667rem;
					}
				}

				.image-wrapper_2 {
					width: 6.667rem;
					height: 6.667rem;
					margin-left: 3.333rem;

					.thumbnail_2 {
						width: 1.12rem;
						height: 2rem;
					}
				}

				.text-wrapper_19 {
					width: 6.667rem;
					height: 6.667rem;
					margin-left: 3.333rem;
					border-radius: 0.667rem;

					.text_73 {
						font-size: 2.667rem;
					}
				}

				.image-wrapper_3 {
					width: 6.667rem;
					height: 6.667rem;
					margin-left: 3.333rem;

					.thumbnail_3 {
						width: 1.12rem;
						height: 2rem;
					}
				}

				.text-wrapper_23 {
					width: 15rem;
					height: 6.667rem;
					margin-left: 3.333rem;
					border-radius: 0.667rem;

					.text_77 {
						font-size: 2.667rem;
						line-height: 2.667rem;
					}
				}
			}
		}
	}
</style>